
/**************************************  扩展数组查询功能  start **************************************/
/**
 * 查询满足条件的多条数据
 * @param {any} callback 回调函数，入参: 数组对象curobj
 */
 Array.prototype.queryItems = function (callback) {
    var arr = new Array()
    for (var i = 0, len = this.length; i < len; i++) {
        var curobj = this[i]
        if (callback(curobj,i) == true) {
            var isobject = (Object.prototype.toString.call(curobj) === '[object Object]')
            arr.push(isobject ? Object.assign(curobj) : curobj)
        }
    }
    return arr
};

/**
 * 查询满足条件的单条数据
 * @param {any} callback 回调函数，入参: 数组对象curobj
 */
Array.prototype.queryItem = function (callback) {
    var arr = new Array()
    for (var i = 0, len = this.length; i < len; i++) {
        var curobj = this[i]
        if (callback(curobj) == true) {
            arr.push(curobj)
            break;
        }
    }
    if (arr.length == 0)
        return {}
    return arr[0]
};

/**
 * 查询数组索引
 * @param {any} callback  回调函数，入参: 数组对象curobj
 */
Array.prototype.queryIndex = function (callback) {
    var index = -1;
    for (var i = 0, len = this.length; i < len; i++) {
        var curobj = this[i]
        if (callback(curobj) == true) {
            index = i
            break
        }
    }
    return index
};

/**
 * 数组最大值
 *  */ 
Array.prototype.maxVal = function (callback) {
    var result = [];
    for (var i = 0, len = this.length; i < len; i++) {
        var curobj = this[i]
        var value = callback && callback(curobj)
        result.push(value)
    }
    if(result.length==0)
       return 0
    return result.sort((a,b)=>b-a)[0]
};

/**
 * 查询数组对象中的数据并返回新对象
 * @param {any} callback 回调函数，入参: 数组对象 curobj,数组索引
 */
Array.prototype.selectItems = function (callback) {
    var arr = new Array()
    for (var i = 0, len = this.length; i < len; i++) {
        var curobj = this[i]
        var newobj = callback(curobj, i)
        if (newobj || newobj=="") {
            var isobject = (Object.prototype.toString.call(newobj) === '[object Object]')
            if (isobject) {
                arr.push(Object.assign(newobj))
            } 
            /*
            else if (newobj instanceof Array) {
                arr = arr.concat(newobj)
            } 
            */
            else {
                arr.push(newobj)
            }
        }
    }
    return arr
};

/**
 * 根据条件移除选项
 * @param {any} callback 回调函数，入参: 数组对象curobj
 */
Array.prototype.removeItems = function (callback) {
    var arrindexs = []
    for (var i = 0, len = this.length; i < len; i++) {
        var curobj = this[i]
        if (callback(curobj) == true) {
            arrindexs.push(i)
        }
    }
    if(arrindexs.length>0){
        var result = arrindexs.reverse()
        result.forEach(index=>{
            this.splice(index, 1)
        })
    }
    
};

/**
 * 根据字段列表分组数据
 * @param {any} fields 字段列表 (字符串数组)
 */
Array.prototype.groupByfields = function (fields,sortcallback) {
    var groups = {}
    var keyarr = []
     for (var i = 0, len = this.length; i < len; i++) {
        var cur = this[i]
        var keys = fields.map(key => cur[key])
        var item = keys.join('_')
        if(keyarr.indexOf(item)<0)
           keyarr.push(item)
        groups[item] = groups[item] || []
        groups[item].push(cur)
    }
    if(sortcallback){
        for(var i=0,len=keyarr.length;i<len;i++){
            var key = keyarr[i]
            var items = groups[key]
            groups[key] = items.sort(sortcallback)
        }
    }
    var result = {
        keys : keyarr,
        groups
    }
    return result
};

/**
 * 数据排序
 * @param {*} fields 
 */
Array.prototype.itemSort = function (fields=[],sortback=null){
    if (fields.length == 0)
        return this.sort()

    var field = fields[0]
    var val = this.queryItem(t=>t[field]!="")[field]
    var type = Object.prototype.toString.call(val)
    var typestr = type.replace("[object ","").replace("]","") 
    if(typestr=="String")
       return this.sort((a,b)=>a[field].localeCompare(b[field]))
    if(typestr=="Number")
       return this.sort((a,b)=>a[field]-b[field])
    return this.sort()
}


/**
 * 获取子对象数组
 *  @param {any} cpropname  { 子节点属性 }
 *  @param {any} extendobj  { parentId: 0,ckeyprop:"id",isparent:true }
 * */
Array.prototype.childItems = function (cpropname, extendobj) {
    var iextendobj = {
        parentId: 0,
        ckeyprop: "id",
        isparent: true,
        level : 0,
        ...extendobj
    }
    var { parentId, ckeyprop, isparent, level } = iextendobj
    var arr = new Array()
    for (var i = 0, len = this.length; i < len; i++) {
        var cur = this[i]
        if (isparent)
            cur.parentId = parentId
       
        if (cur[cpropname]) {
            var arrprop = cur[cpropname]
            if (Array.isArray(arrprop)) {
                var childlevel = level+1
                var extendchild = { ...iextendobj , level : childlevel  }
                if (isparent) {
                    var keyflag = ckeyprop ? cur[ckeyprop] : `${parentId}_${i}`
                    extendchild.parentId = keyflag
                }
                var childItems = arrprop.childItems(cpropname, extendchild)
                arr = arr.concat(childItems)
            }
        }
        var newcur = { ...cur,level }
        if (newcur[cpropname])
            delete newcur[cpropname]
        arr.push(newcur)
    }
    return arr
};

/**
 * 树选项
 * @returns 
 */
Array.prototype.treeItems = function({cprop,newprop,cparam}){
    var recurse_func = function(arr,datas){
        for (var i = 0, len = datas.length; i < len; i++) {
            var data = datas[i]
            var treenode = cparam(data) || {}
            if (data[cprop]){
                treenode[newprop] = recurse_func([],data[cprop])
            }
            arr.push(treenode)
        }
        return arr
    }

    var result = recurse_func([],this)
    return result
};

/** 
 * 将某些值更改成某些值 
 * **/
Array.prototype.updatedVals = function(oldval,newval){
    var func = function(curobj){
        var isobject = (Object.prototype.toString.call(curobj) === '[object Object]')
        if(isobject){
            for (let key in curobj) {
                var val = curobj[key]
                if(val==oldval){
                    curobj[key] = newval
                }
                else if(val instanceof Array){
                    val.updatedVals(oldval,newval)
                }else if(Object.prototype.toString.call(val) === '[object Object]'){
                    func(val)
                }
            }
        }else{
            if(curobj==oldval){
                curobj = newval
            }
        }
    }

    for (var i = 0, len = this.length; i < len; i++) {
        var curval = this[i]
        func(curval)
    }
};

/**
 *  取中间那个点
 */
Array.prototype.centerItem = function(){
    var d1 = this.length % 2
    var index = (d1==0)?(this.length/2-1):((this.length-1)/2-1)
    return this[index]
}

/**
 * 去重
 */
Array.prototype.distinct = function(){
    var that = this
    return that.filter(function(item,index){
        return that.indexOf(item) === index   
    })
}
/**
 * 交集
 */
Array.prototype.intersect = function(arr){
   var result = this.filter(function(v){
     return arr.indexOf(v)!=-1
   })
   return result
}
/* 
 * 差集
 */
Array.prototype.minus = function(arr){
    var innerarr = this.intersect(arr)
    var result = this.filter(function(v){
        return innerarr.indexOf(v) == -1
    })
    return result
}


/**************************************  扩展数组查询功能  end **************************************/

/**************************************  Date扩展  start **************************************/
/**
 * 格式化日期
 * @param {any} formatstr
 */
Date.prototype.format = function (formatstr) {
    if (/(y+)/.test(formatstr)) {
        formatstr = formatstr.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length))
    }
    var childobj = {
        "M+": this.getMonth() + 1,               //月份 
        "d+": this.getDate(),                    //日 
        "h+": this.getHours(),                   //小时 
        "m+": this.getMinutes(),                 //分 
        "s+": this.getSeconds(),                 //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds()              //毫秒 
    }
    for (var prop in childobj) {
        var curvale = childobj[prop]
        if (new RegExp("(" + prop + ")").test(formatstr)) {
            var value = (RegExp.$1.length == 1) ?
                curvale :
                ("00" + curvale).substr(("" + curvale).length)

            formatstr = formatstr.replace(RegExp.$1, value)
        }
    }
    return formatstr
};
/**************************************  Date扩展  end **************************************/

/**************************************  String扩展  start **************************************/
String.prototype.insert = function (index,str){
   var result =[]
   if(index>this.length)
      index = this.length-1
   for(var i=0,len=this.length ; i<len ; i++){
      result.push(this[i])
   }
   result.splice(index,0,str)
   return result.join("")
}
String.prototype.insertReplace = function (index,newstr,replaceStr){
    var result =[]
    if(index>this.length)
       index = this.length-1
    for(var i=0,len=this.length ; i<len ; i++){
       result.push(this[i])
    }
    var len = replaceStr.length
    var replaceIndex = len>0 ? len : 0
    result.splice(index,replaceIndex,newstr)
    return result.join("")
 }

 Number.prototype.toArray = function(start=0){
    var result = []
    for(var i=start,len=this ; i<=len ; i++){
        result.push(i)
     }
    return result
 }
/**************************************  String扩展  end **************************************/

/**************************************  Object扩展  start **************************************/
Object.isEqual = function(o1,o2){
    o1 = o1||{}
    o2 = o2||{}
    var props1 = Object.getOwnPropertyNames(o1)
    var props2 = Object.getOwnPropertyNames(o2)
    if (props1.length != props2.length) {
        return false
    }
    for (var i = 0,max = props1.length; i < max; i++) {
        var propName = props1[i]
        if (o1[propName] !== o2[propName]) {
           return false
        }
    }
    return true
}


/**************************************  Object扩展  end **************************************/