<template>
    <a-layout>
      <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
          <div class="displayflex">
              <div class="logo" >
                <img :width="80" src="/Sources/logo.jpg" />
              </div>
              <div :style="{ width:'calc(100% - 80px)' }">
                  <a-menu theme="light" mode="horizontal" :style="{ lineHeight: '64px' }" >
                      <a-menu-item :key="1" >
                        <router-link to="/">{{ $t("menu.menu1") }}</router-link>
                      </a-menu-item>
                      <a-menu-item :key="8" >
                        <router-link to="/about">{{ $t("menu.menu2") }}</router-link>
                      </a-menu-item>
                      <a-menu-item :key="2" >
                        <router-link to="/Products">{{ $t("menu.menu3") }}</router-link>
                      </a-menu-item>
                      <a-menu-item :key="3" >
                        <router-link to="/Services">{{ $t("menu.menu4") }}</router-link>
                      </a-menu-item>
                      <a-menu-item :key="6" >
                        <router-link to="/Concat">{{ $t("menu.menu5") }}</router-link>
                      </a-menu-item>
                      <a-menu-item :key="7" >
                        <router-link to="/Vedios">{{ $t("menu.menu6") }}</router-link>
                      </a-menu-item>
                  </a-menu>
              </div>
          </div>
      </a-layout-header>
      <router-view />
      <a-layout-footer :style="{ textAlign: 'center' }">
        {{ $t(`info.name.${company}`) }} ©2024
      </a-layout-footer>
  </a-layout>
</template>

<script>

import mainConst from '@/assets/const'
import { message } from 'ant-design-vue'

export default {
  data(){
    return {
      locale:this.$locale,
      company:mainConst.company,
      loading:true
    }
  },
  methods:{
  }
}
</script>