// 语言
var Lang = {
   jp : "jp",  // 日文
   zh : "zh",  // 中文 
}
// 公司
var company = {
    dalian : "01",
    qingdao : "02",
}

export default class{
    static message = function(msg){
    }
    static langage = Lang.zh
    static company = company.qingdao
}