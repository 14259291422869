const routes = [
    {
        name: "Home",
        path: "/",
        component: () => import("../views/Home/Index.vue")
    },
    {
        name: "About",
        path: "/About",
        component: () => import("../views/About/Index.vue")
    },
    // Products
    {
        name: "Products",
        path: "/Products",
        component: () => import("../views/Products/Index.vue")
    },
    {
        name: "ProductDetail",
        path: "/ProductDetail",
        component: () => import("../views/Products/Detail.vue")
    },
    // 
    {
        name: "Services",
        path: "/Services",
        component: () => import("../views/Other/Services.vue")
    },
    {
        name: "Concat",
        path: "/Concat",
        component: () => import("../views/Concat/Index.vue")
    },
    {
        name: "Vedios",
        path: "/Vedios",
        component: () => import("../views/Other/Vedios.vue")
    },
]
export default routes