
import { createI18n } from 'vue-i18n';
import ZH from './childs/zh.js';
import JP from './childs/jp.js';
import mainConst from '@/assets/const'

var langage= mainConst.langage
const messages = {
  zh: { ...ZH  },
  jp: { ...JP  },
}
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: langage,
  messages
})
export default i18n