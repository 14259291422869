import constConfig from './const'

export default ($request) => {
    // 默认成功执行方法
    var defaultSuccess = function(res){
       return res.data
    }
    var defaultFail = function(res){

    }
    var getParams = function(param){
        return Object.keys(param).selectItems(key=>(`${key}=${param[key]}`))
    }
    /**
     * 
     * @param {
     *    extendparam : {
     *       headers : { ... },  // 请求头参数
     *       message() {},       // 消息提示
     *  
     *    }
     * } 
     */
    var req_api = function({
        url,
        param,
        method,
        success,
        fail,
        extendparam
    }){
        var url = `${url}`
        var reqmethod = method || 'post'

        if(!success)
           success = defaultSuccess
        if(!fail)
           fail = defaultFail

        var msgTip = extendparam && extendparam.message || (constConfig.message || function(){} )
        // 默认处理结果方法
        var defaultResult = function(res){
            if(res && res.code==1){
                // 处理过程
                return success(res)
            }
            msgTip(res.msg)
            return fail(res)
        }

        var isexist_qp = false
        // 添加扩展
        if (extendparam){
            // headers
            if (extendparam.headers)
                $request.defaults.headers = {
                    ...$request.defaults.headers,
                    ...extendparam.headers
                }
            // 请求参数
            if (extendparam.queryparam){
                isexist_qp = true
                var getparams = getParams(extendparam.queryparam)
                url += `?${getparams.join('&') }`
            }
        }

        // 请求方法
        if(reqmethod=="get"){
            if (param){
                var getparams = getParams(param)
                url += (isexist_qp ? "&" : "?") + `${getparams.join('&') }`
            }
            return $request.get(url).then(defaultResult)
        }
        return $request.post(url,param).then(defaultResult)
    }
    return req_api
}