
export default {
    info: {
      name:{
        "01":'马达西奇飞机工业（大连）有限公司',
        "02":'马达西奇直升机（青岛）有限公司',
      }
    },
    menu:{
      menu1:'首页',
      menu2:'关于',
      menu3:'产品',
      menu4:'服务',
      menu5:'联系我们',
      menu6:'视频',
    },
    other:{
      info1:"推荐信息",
      product:{
        name1:"详细"
      },
      contact:{
        zhuanlilist:"发明专利列表",
        zhuanliname:"专利名称"
      }
    }
}