<template>
    <a-config-provider :locale="locale">
        <home-index v-if="loading==false"></home-index>
    </a-config-provider>
</template>

<script>
import axios from 'axios'
import constConfig from './assets/const'
import { defineComponent } from "vue"
import mainConst from '@/assets/const'
import { message } from 'ant-design-vue'
import HomeIndex from './views/Index'

var key = "data01"

export default {
  name: 'App',
  components: {
    HomeIndex
  },
  data(){
		return {
			locale: this.$locale,
      company: mainConst.company,
      loading: true
		}
	},
  created(){
    var that = this
    var langage= mainConst.langage
    var company = mainConst.company

    if(this.$store.state.hasload == true){
      that.loading = false
      return
    }
       

    message.loading({ content: 'Loading...', key})
    this.$request({
      url:`./configs/${company}/config.${langage}.json?v=1.0`,
      method:'get'
    }).then(res=>{
       for(var p in res){
           that.$store.commit('saveState',{
              prop:p,
              val:res[p]
           })
       }
       // 提交状态
       that.$store.commit('saveState',{ prop: "hasload", val: true })
    }).then(res=>{
       that.loading = false
       message.success({ content: 'Loaded', key, duration: 0.1 })
    })
  }
}
</script>