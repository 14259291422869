import { createApp } from 'vue'
import App from './App.vue'

var app = createApp(App)

// 添加ant-design-vue
import antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './assets/styles/common.css'
app.use(antd)


// 引入中文


import locale_cn from "ant-design-vue/lib/locale-provider/zh_CN"
import locale_jp from "ant-design-vue/lib/locale-provider/ja_JP"

import mainConst from '@/assets/const'
var langage= mainConst.langage
var langage_name = locale_cn
switch(langage){
    case "jp":
        langage_name = locale_jp
        break;
}

app.config.globalProperties.$locale = langage_name

// 添加router
import router from "./router/index"
app.use(router)

// 添加扩展
import './extends/JsExtends.js'

// 添加全局持久化
import store from '@/assets/store/index'
app.use(store)

// 添加Api
import createRequest from '@/assets/request'
import createRequestApi from '@/assets/requestapi'

var apiurl = "http://localhost:8080/"
if(process.env.NODE_ENV=='production'){
    apiurl = "/"
}
const request = createRequest(apiurl)
const requestapi = createRequestApi(request)
app.config.globalProperties.$request = request
app.config.globalProperties.$apirequest = requestapi

// 国际化
import i18n  from './il18n/main'
app.use(i18n)

app.mount('#app')
