import axios from 'axios'

export default (baseUrl, prefix) => {
    const request = axios.create()
    // 基础配置
    request.defaults.timeout = 60000
    request.defaults.baseURL = `${baseUrl}${prefix || ''}`
    request.defaults.withCredentials = true
    request.defaults.headers = {
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers':'X-Requested-With'
    }

    // 授权失败，跳转方法
    function onTokenExpired() {
        
    }

    // request拦截器
    request.interceptors.request.use(
        config => {
            config.headers = {
                ...config.headers
            }
            // 记录直传response的请求
            if (config.config && config.config.passResponse) {
                passResponse.add(config.url + '&' + config.method)
            }
            return config
        },
        error => {
            return Promise.reject(error)
        }
    )

    // response拦截器
    request.interceptors.response.use(
        response => {
            /*
            if(response.data.code==401){
                onTokenExpired()
                return {
                    code:401,
                    msg:'没有授权，准备跳转到登录页'
                }
            }
            */
            return response.data
        },
        error => {
            if (axios.isCancel(error)) 
              return
            if (error.response.status == 401) {
              onTokenExpired()
              return Promise.resolve({
                  code:401,
                  msg:'没有授权，准备跳转到登录页'
              })
            } else {
               console.log("请求异常",error.response)
            }
            return Promise.reject(error)
        }
    )

    return request
}