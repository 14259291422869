
export default {
  info: {
    name:{
      "01":'モトール・シーチ航空エンジン（大連）有限会社',
      "02":'马达西奇直升机（青岛）有限公司',
    },
  },
  menu:{
    menu1:'Home',
    menu2:'About',
    menu3:'Products',
    menu4:'Service',
    menu5:'Concat Us',
    menu6:'Vedio',
  },
  other:{
    info1:"推奨情報",
    product:{
      name1:"詳細"
    },
    contact:{
      zhuanlilist:"特許リスト",
      zhuanliname:"特許の名称"
    }
  }
}